/**
 * Layout is the wrapper around every page
 * It is called by <Layout><div className="test">Child</div></Layout>
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import parse from "html-react-parser"
import Header from "./header/header"
import Footer from "./footer/footer";
import PropTypes from "prop-types";
import AdSquare from "./ad/ad-square";
import FooterAdvertorials from "./footer/advertorials";
import AboutMe from "./home/home-aboutme";
//import {usePost} from "./react/api";

/**
 * Define the Layout constant
 * @param isHomePage
 * @param isRecipePage
 * @param children
 * @param advertorials
 * @returns {JSX.Element}
 * @constructor
 */
const Layout = ({isHomePage, isRecipePage, children, advertorials}) => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------
    const {
        wp: {
            generalSettings: {title},
        },
    } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------

    // get the login state
    /*
  const loginState = usePost({
      url:`${process.env.GATSBY_ACCOUNT_URL}/ajax/`,
      formData: formData()
  }).data*/

    const loginState = null;

    // return the information
    return (
        <div id="stickyfooterwrapper" data-is-root-path={isHomePage}>
            <Header siteTitle={parse(title)} loginState={loginState}/>
            <div className="wrap container">
                <main className="main">{children}</main>
            </div>
            <AboutMe isHomePage={isHomePage}/>
            <AdSquare isRecipePage={isRecipePage}/>
            <FooterAdvertorials isRecipePage={isRecipePage} advertorials={advertorials}/>
            <Footer siteTitle={parse(title)}/>
        </div>
    )
}

/**
 * Define the data type for the variable layoutClass
 * @type {{layoutClass: Requireable<string>}}
 */
Layout.propTypes = {
    layoutClass: PropTypes.string
}

/**
 * Export the layout constant
 */
export default Layout

/**
 * Get the session ID of the user
 * @returns {string|boolean}
 */
/*const sessionId =
    ( /SESS\w*ID=([^;]+)/i.test(document.cookie) && typeof document !== "undefined")
        ? RegExp.$1
        : false*/
//const sessionId = false

/**
 * Get the form data
 * @returns {FormData}
 */
/*const formData = () => {
    const data = new FormData();
    data.append( 'ajax', 'GetLoginState' );
    data.append( 'session_id', sessionId );
    return data;
}*/