/**
 * Square ad for in the content
 */

// Import statements
import * as React from "react"

/**
 * Define the AdSquare constant
 * @param isRecipePage
 * @returns {JSX.Element|string}
 * @constructor
 */
const AdSquare = ({isRecipePage}) => {

    // only show on single recipe pages
    if (isRecipePage !== true) {
        return '';
    }

    // return information
    return (
        <div id="banner-square-wrapper" className="container-fluid"></div>
    )
}

/**
 * Export the AdSquare variable
 */
export default AdSquare
