/**
 * Header wrapper component
 */

// Import statements
import * as React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby"
//import Logo from "-!svg-react-loader?props[]=title:My Foodblog!../../../content/svg/mfb_logo_gatsby.svg";
import HeaderSearch from "./header-search";
//import Logo from "../common/logo";
import {StaticImage} from "gatsby-plugin-image";

/**
 * Check whether the menu item is active or not
 * @param isCurrent
 * @returns {{className: string}|{className: string}}
 */
const isActive = ({isCurrent}) => {
    return isCurrent ? {className: "nav-link active"} : {className: "nav-link"}
}

/**
 * Define the ExactNavLink constant
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExactNavLink = props => (
    <Link getProps={isActive} {...props} />
)

/**
 * Define the Header constant
 * @param siteTitle
 * @param loginState
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({siteTitle, loginState}) => {

    // query primary menu
    const menu = useStaticQuery(
        graphql`
      query {
        wpMenu(locations: {eq: PRIMARY_NAVIGATION}) {
          id
          name
          menuItems {
            nodes {
              id
              label
              title
              path
              parentId
            }
          }
        }
      }
    `
    );


    // convert to hierarchy
    let hierarchicalMenu = flatListToHierarchical(menu.wpMenu.menuItems.nodes);

    // return the header markup
    return (
        <header className="banner bg-light">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <h1>
                        <Link to="/" className="navbar-brand" id="logo">
                            {/*<Logo J={`#245c58`} K={`#f68548`} L={`#a78e37`} M={`#163b32`} N={`#bee8cf`} O={`#e64e3f`} P={`#fcd9d7`} height={50} width={200}  />*/}
                            <StaticImage
                                src="../../../content/images/LogoMyFoodblog2.png"
                                alt={`My Foodblog`}
                                placeholder="none"
                                width={200}
                                height={50}
                            />
                        </Link>
                    </h1>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className="navbar-nav" id="menu-main-menu">
                            <li className="nav-item"><ExactNavLink to="/">{hierarchicalMenu[0].label}</ExactNavLink>
                            </li>
                            <li className="nav-item"><ExactNavLink
                                to={hierarchicalMenu[1].path}>{hierarchicalMenu[1].label}</ExactNavLink></li>
                            <li className="nav-item"><ExactNavLink
                                to={hierarchicalMenu[2].path}>{hierarchicalMenu[2].label}</ExactNavLink></li>
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle" role="button" data-toggle="dropdown"
                                   aria-expanded="false" href="/#">{hierarchicalMenu[3].label}<b className="caret"></b></a>
                                <ul className="dropdown-menu">
                                    {
                                        hierarchicalMenu[3].children.map(child => (
                                            <li key={child.label} className="dropdown-item"><ExactNavLink
                                                to={child.path}>{child.label}</ExactNavLink></li>
                                        ))
                                    }
                                </ul>
                            </li>
                            <li className="nav-item"><ExactNavLink
                                to={hierarchicalMenu[4].path}>{hierarchicalMenu[4].label}</ExactNavLink></li>
                        </ul>
                        {loginState !== null && ['free', 'subscribed'].includes(loginState.state) && (
                            <ul id="menu-account-menu" className="nav navbar-nav mb-2 mb-lg-0">
                                <li itemProp="name" className="nav-item dropdown menu-account">
                                    <a className="nav-link dropdown-toggle" href={`${process.env.GATSBY_ACCOUNT_URL}/`}
                                       role="button" data-toggle="dropdown" aria-expanded="false">Mijn account <b
                                        className="caret"></b></a>
                                    <ul className="dropdown-menu">
                                        <li itemProp="name" className="dropdown-item menu-account-dashboard">
                                            <a className="dropdown-item" href={`${process.env.GATSBY_ACCOUNT_URL}/`}>Mijn
                                                account</a>
                                        </li>
                                        <li itemProp="name" className="dropdown-item menu-account-recipegroups">
                                            <a className="dropdown-item"
                                               href={`${process.env.GATSBY_ACCOUNT_URL}/account/recipe-groups/`}>Receptenverzamelingen</a>
                                        </li>
                                        <li itemProp="name" className="dropdown-item menu-account-settings">
                                            <a className="dropdown-item"
                                               href={`${process.env.GATSBY_ACCOUNT_URL}/account/settings/`}>Instellingen</a>
                                        </li>
                                        <li itemProp="name" className="dropdown-item menu-account-profile-images">
                                            <a className="dropdown-item"
                                               href={`${process.env.GATSBY_ACCOUNT_URL}/account/profile-images/`}>Profielfoto's</a>
                                        </li>
                                        <li itemProp="name" className="dropdown-item menu-logout">
                                            <a className="dropdown-item"
                                               href={`${process.env.GATSBY_ACCOUNT_URL}/logout/`}>Uitloggen</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        )}
                        {(loginState === null || !['free', 'subscribed'].includes(loginState.state)) && (
                            <ul id="menu-account-menu" className="nav navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item menu-login"><a
                                    href={process.env.GATSBY_ACCOUNT_URL}>Inloggen</a></li>
                            </ul>
                        )}
                    </div>
                </div>
            </nav>
            <HeaderSearch/>
        </header>
    )
}

/**
 * Convert the flat menu structure from GraphQL to an hierarchical one
 * @param data
 * @param idKey
 * @param parentKey
 * @param childrenKey
 * @returns {*[]}
 */
const flatListToHierarchical = (
    data = [],
    {idKey = "id", parentKey = "parentId", childrenKey = "children"} = {}
) => {
    const tree = []
    const childrenOf = {}
    data.forEach(item => {
        const newItem = {...item}
        const {[idKey]: id, [parentKey]: parentId = 0} = newItem
        childrenOf[id] = childrenOf[id] || []
        newItem[childrenKey] = childrenOf[id]
        parentId
            ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
            : tree.push(newItem)
    })
    return tree
}

/**
 * Define the datatypes of the variables used in this component
 * @type {{siteTitle: Requireable<string>}}
 */
Header.propTypes = {
    siteTitle: PropTypes.string,
}

/**
 * Set the default values of the variables used in this component
 * @type {{siteTitle: string}}
 */
Header.defaultProps = {
    siteTitle: ``,
}

/**
 * Export the Header variable
 */
export default Header
