/**
 * Shortcuts Menu in the footer
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import NavPills from "../common/nav-pills";

/**
 * Define the ShortcutsMenu component
 * @param menu
 * @returns {JSX.Element}
 * @constructor
 */
const ShortcutsMenu = ({menu}) => {

    // query shortcuts menu
    const shortcuts_menu_data = useStaticQuery(
        graphql`
          query {
            wpMenu(locations: {eq: SHORTCUTS_NAVIGATION}) {
              id
              name
              menuItems {
                nodes {
                  parentId
                  label
                  path
                  childItems {
                    nodes {
                      path
                      label
                    }
                  }
                }
              }
            }
          }
        `
    );

    // get parent items of the shortcuts menu
    const shortcuts_menu = shortcuts_menu_data.wpMenu.menuItems.nodes
        ? shortcuts_menu_data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
        : null

    // return the shortcut menu
    return (
        <NavPills menu={shortcuts_menu} ul_class="nav nav-pills flex-column"/>
    )
}

/**
 * Export the menu
 */
export default ShortcutsMenu