/**
 * Search Result component used for FlexSearch autocomplete
 */

// Import statements
import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import parse from "html-react-parser";

/**
 * Define the SearchResult constant
 * @param post
 * @param index
 * @param featured
 * @param query
 * @returns {JSX.Element}
 * @constructor
 */
const SearchResult = ({post, index, featured, query}) => {

    // define variables
    const title = post.title
    const image = (post.searchResultImage !== null) ? getImage(post.searchResultImage.node.localFile) : ""

    // build the card
    return (
        <a className="list-group-item list-group-item-action" href={post.uri} title={title}>
            <GatsbyImage image={image} alt={title} title={title} style={{
                maxHeight: "50px",
                maxWidth: "50px",
                minHeight: "50px",
                minWidth: "50px",
                height: "50px",
                width: "50px"
            }}/>
            <span className="suggestion-text">{parse(highlight(title, query))}</span>
        </a>
    )
}

/**
 * Export the SearchResult variable
 */
export default SearchResult

/**
 * Highlight the query in the search result items
 * @param text
 * @param query
 * @returns {*}
 */
function highlight(text, query) {
    return text.replace(
        new RegExp(query + '(?!([^<]+)?<)', 'gi'),
        '<mark>$&</mark>'
    );
}