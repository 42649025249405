/**
 * Menu column 5 in the footer
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import NavPills from "../common/nav-pills";

/**
 * Define the FooterColumnFiveMenu component
 * @param menu
 * @returns {JSX.Element}
 * @constructor
 */
const FooterColumnFiveMenu = ({menu}) => {

    // query col_5 menu
    const col_5_menu_data = useStaticQuery(
        graphql`
          query {
            wpMenu(locations: {eq: FOOTER_5_NAVIGATION}) {
              id
              name
              menuItems {
                nodes {
                  parentId
                  label
                  path
                  childItems {
                    nodes {
                      path
                      label
                    }
                  }
                }
              }
            }
          }
        `
    );

    // get parent items of the col_5 menu
    const col_5_menu = col_5_menu_data.wpMenu.menuItems.nodes
        ? col_5_menu_data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
        : null

    // return the col_5 menu
    return (
        <section className="col-md-2 col-sm-4 col-xs-6">
            <h3>{col_5_menu_data.wpMenu.name}</h3>
            <NavPills menu={col_5_menu} ul_class="nav nav-pills flex-column"/>
        </section>

    )
}

/**
 * Export the menu
 */
export default FooterColumnFiveMenu