/**
 * AboutMe component to display Judith's info on the homepage
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";

/**
 * Define the AdFooAboutMeter constant
 * @returns {JSX.Element}
 * @constructor
 */
const AboutMe = ({isHomePage}) => {

    // get author information with GraphQL
    const author = useStaticQuery(graphql`
    query AboutMe {
      # if there was more than one user, this would need to be filtered
      author: wpUser(firstName: {eq: "Judith"}) {
        firstName
        twitter: name
        description
        avatar {
          url
        }
        uri
      }
      gravatar(email: { eq: "judith@myfoodblog.nl" }) {
        url
        localImage {
          childImageSharp {
            gatsbyImageData (
              quality: 50
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
              breakpoints: [300]
            )
          }
        }
      }
    }
  `)

    // return empty string when this is the homepage
    if (isHomePage !== true) {
        return '';
    }


    ///
    ///  https://medium.com/swlh/optimizing-gravatars-in-gatsby-ccf8cf359ccf
    ///  https://www.gatsbyjs.com/docs/how-to/images-and-media/preprocessing-external-images/
    ///
    ///
    //const avatarUrl = author?.author?.avatar?.url.replace( 's=96', 's=300' )
    const avatarUrl = getImage(author?.gravatar?.localImage.childImageSharp)

    //const image = ( post.featuredImage !== null ) ? getImage(post.featuredImage.node.localFile) : ""

    // return the information
    return (
        <article id="aboutme" className={``}>
            <div className="image_wrapper">
                <StaticImage
                    src="../../../content/images/marmer.webp"
                    alt={`marmer`}
                    placeholder="blurred"
                    layout="constrained"
                    fit="cover"
                />
            </div>
            <div className="aboutme-img-overlay container d-flex align-items-center p-4 p-sm-2 p-md-3">
                <div className={`row`}>
                    <div className={`col-sm-6 text-center p-5`}>
                        <h2>Welkom op My Foodblog</h2>
                        <p>{author?.author?.description || null}</p>
                        <p className={`handwritten`}>Liefs Judith</p>
                    </div>
                    <div className={`col-sm-6 text-center`} id={`avatar-wrapper`}>
                        <a href={author?.author?.uri || null} itemProp="url" title={author?.author?.firstName || null}>
                            {avatarUrl && (
                                <GatsbyImage
                                    image={avatarUrl}
                                    alt={author?.author?.firstName || ``}
                                    className="bio-avatar"
                                    width="300"
                                />
                            )}
                        </a>
                    </div>
                </div>
            </div>
        </article>
    )
}

/**
 * Export the AboutMe constant
 */
export default AboutMe