/**
 * Advertorials for in the footer
 * This is displayed on single recipe posts
 */

// Import statements
import React from "react"
import Card from "../common/card";

/**
 * Define the FooterAdvertorials constant
 * @param isRecipePage
 * @param advertorials
 * @returns {JSX.Element|string}
 * @constructor
 */
const FooterAdvertorials = ({isRecipePage, advertorials}) => {

    if (isRecipePage !== true) {
        return '';
    }

    // return the advertorials block
    return (
        <div id="advertorials">
            <div className="container">
                <h2 className="title">Uitgelicht</h2>
                <div id="advertorials-card-wrapper" className="row g-4 g-sm-2 g-md-4">
                    {advertorials.nodes.map((post, index) => {
                        return (<Card post={post} index={index} key={index} featured="false"/>)
                    })}
                </div>
            </div>
        </div>
    )
}

/**
 * Export the FooterAdvertorials variable
 */
export default FooterAdvertorials