/**
 * Copyright Menu in the footer
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import NavPills from "../common/nav-pills";

/**
 * Define the CopyrightMenu component
 * @param menu
 * @returns {JSX.Element}
 * @constructor
 */
const CopyrightMenu = ({menu}) => {

    // query copyright menu
    const copyright_menu_data = useStaticQuery(
        graphql`
          query {
            wpMenu(locations: {eq: COPYRIGHT_NAVIGATION}) {
              id
              name
              menuItems {
                nodes {
                  parentId
                  label
                  path
                  childItems {
                    nodes {
                      path
                      label
                    }
                  }
                }
              }
            }
          }
        `
    );

    // get parent items of the copyright menu
    const copyright_menu = copyright_menu_data.wpMenu.menuItems.nodes
        ? copyright_menu_data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
        : null

    // return the copyright menu
    return (
        <NavPills menu={copyright_menu} ul_class="copyright_navigation"/>
    )
}

/**
 * Export the menu
 */
export default CopyrightMenu