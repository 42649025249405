/**
 * About us menu in the footer
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import NavPills from "../common/nav-pills";

/**
 * Define the AboutUsMenu component
 * @param menu
 * @returns {JSX.Element}
 * @constructor
 */
const AboutUsMenu = ({menu}) => {

    // query about_us menu
    const about_us_menu_data = useStaticQuery(
        graphql`
          query {
            wpMenu(locations: {eq: ABOUT_US_NAVIGATION}) {
              id
              name
              menuItems {
                nodes {
                  parentId
                  label
                  path
                  childItems {
                    nodes {
                      path
                      label
                    }
                  }
                }
              }
            }
          }
        `
    );

    // get parent items of the about_us menu
    const about_us_menu = about_us_menu_data.wpMenu.menuItems.nodes
        ? about_us_menu_data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
        : null

    // return the about us menu
    return (
        <NavPills menu={about_us_menu} ul_class="nav nav-pills flex-column"/>
    )
}

/**
 * Export the menu
 */
export default AboutUsMenu