/**
 * Footer wrapper component.
 * Groups all subcomponents together
 */

// Import statements
import * as React from "react"
import PropTypes from "prop-types"
import ShortcutsMenu from "./footer-shortcuts-menu";
import AboutUsMenu from "./footer-about-us-menu";
import CopyrightMenu from "./footer-copyright-menu";
import FooterColumnOneMenu from "./footer-column-1-menu";
import FooterColumnTwoMenu from "./footer-column-2-menu";
import FooterColumnThreeMenu from "./footer-column-3-menu";
import FooterColumnFourMenu from "./footer-column-4-menu";
import FooterColumnFiveMenu from "./footer-column-5-menu";
import FooterColumnSixMenu from "./footer-column-6-menu";

/**
 * Define the Footer constant
 * @param siteTitle
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({siteTitle}) => {

    return (
        <footer className="content-info">
            <nav className="footer-sitemap light-grey">
                <div className="container">
                    <div className="row">
                        <FooterColumnOneMenu/>
                        <FooterColumnTwoMenu/>
                        <FooterColumnThreeMenu/>
                        <FooterColumnFourMenu/>
                        <FooterColumnFiveMenu/>
                        <FooterColumnSixMenu/>
                    </div>
                </div>
            </nav>
            <div className="dark-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 order-md-2" id="newsletter">
                            <h2>My food updates</h2>
                            <p>Schrijf je in om wekelijks de lekkerste recepten in je mailbox te ontvangen!</p>
                            <form noValidate="" target="_blank" name="mc-embedded-subscribe-form"
                                  id="footer-mc-embedded-subscribe-form" method="post"
                                  action="//myfoodblog.us9.list-manage.com/subscribe/post?u=9bd80dd22342212734411b651&amp;id=5a967ed79d">
                                <div className="mb-3">
                                    <label htmlFor="footer-mce-EMAIL" className="form-label">E-mailadres</label>
                                    <input type="email" placeholder="E-mailadres (verplicht)" id="footer-mce-EMAIL"
                                           className="form-control" name="EMAIL"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="footer-mce-FNAME" className="form-label">Voornaam</label>
                                    <input type="text" placeholder="Voornaam (verplicht)" id="footer-mce-FNAME"
                                           className="form-control" name="FNAME"/>
                                </div>
                                <button type="submit" className="btn btn-white-transparent"
                                        aria-label="Stuur mij lekkere recepten">Stuur mij lekkere recepten
                                </button>
                            </form>
                        </div>
                        <div className="col-md-9 order-md-1">
                            <div className="row">
                                <div className="col-sm-3" id="shortcuts">
                                    <h2>Snel naar</h2>
                                    <ShortcutsMenu/>
                                </div>
                                <div className="col-sm-3" id="about_us">
                                    <h2>Info</h2>
                                    <AboutUsMenu/>
                                </div>
                                <div className="col-sm-6" id="social_media">
                                    <h2>Volg Myfoodblog.nl</h2>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a className="facebook" href="https://www.facebook.com/myfoodblog.nl" aria-label="Bekijk My Foodblog op Facebook">
                                                <span className="icon icon-facebook-1"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="pinterest" href="https://www.pinterest.com/judithpagrach" aria-label="Bekijk My Foodblog op Pinterest">
                                                <span className="icon icon-pinterest-1"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="instagram" href="https://www.instagram.com/myfoodblog_nl" aria-label="Bekijk My Foodblog op Instagram">
                                                <span className="icon icon-instagram-1"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" id="copyright">
                                    <p className="copyright_sign">© {siteTitle} {new Date().getFullYear()}</p>
                                    <CopyrightMenu/>
                                    <p className="webdesign"><a href="https://oscin.nl"
                                                                title="Webdesign">Webdesign</a> by Oscin</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

/**
 * Define the datatypes of this component variables
 * @type {{siteTitle: Requireable<string>}}
 */
Footer.propTypes = {
    siteTitle: PropTypes.string,
}

/**
 * Define the default values of this component variables
 * @type {{siteTitle: string}}
 */
Footer.defaultProps = {
    siteTitle: ``,
}

/**
 * Export the Footer variable
 */
export default Footer
