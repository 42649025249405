/**
 * Search bar in the header
 */

// Import statements
import * as React from "react"
import {useEffect, useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {useFlexSearch} from "react-use-flexsearch";
import SearchResult from "./search-result";

/**
 * Define the HeaderSearch constant
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderSearch = () => {

    // texts
    const placeholder = "Chocoladetaart, granola etc.";
    const search = "Zoeken";
    const advanced_search = "Geavanceerd zoeken";

    const queryData = useStaticQuery(graphql`
      query {
        localSearchPosts {
          index
          store
        }
        localSearchRecipes {
          index
          store
        }
        localSearchHotspots {
          index
          store
        }
      }
    `)

    // get all indexes & stores
    const index_posts = queryData.localSearchPosts.index
    const store_posts = queryData.localSearchPosts.store
    const index_recipes = queryData.localSearchRecipes.index
    const store_recipes = queryData.localSearchRecipes.store
    const index_hotspots = queryData.localSearchHotspots.index
    const store_hotspots = queryData.localSearchHotspots.store

    // get query
    const [query, setQuery] = useState('')
    useEffect(() => {
        if (typeof window !== `undefined`) {
            const urlParams = new URLSearchParams(window.location.search);
            const queryString = urlParams.get("s")
            if (queryString) {
                setQuery(queryString);
            }
        }
    }, []);

    // search for the query in the index, return right results and add those to an array
    const results_posts = useFlexSearch(query, index_posts, store_posts);
    const results_recipes = useFlexSearch(query, index_recipes, store_recipes);
    const results_hotspots = useFlexSearch(query, index_hotspots, store_hotspots);

    // start with empty array
    const results = [
        results_posts,
        results_recipes,
        results_hotspots
    ];

    // sort the array
    results.sort(function (a, b) {
        return b.length - a.length;
    });

    // return the header markup
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-9" id="search-bar">
                    <form role="search" method="get" className="form-horizontal" id="searchform" action="/search/">
                        <div className="input-group input-group-lg">
                            <input id="s" name="s" onChange={(event) => setQuery(event.target.value)} type="text"
                                   className="form-control typeahead" placeholder={placeholder} aria-label={search}
                                   autoComplete="off" autoCorrect="off"/>
                            <button className="btn btn-primary" type="submit" rel="search" aria-label={search}><span
                                className="icon icon-search"></span></button>
                        </div>
                        <div id="autocomplete-results-wrapper" className="mt-1">
                            {processResults(
                                results,
                                query,
                                calculateLinesPerPostType(results, 10))
                            }
                        </div>
                    </form>
                </div>
                <div className="col-md-3 text-right d-none d-md-block" id="search-bar-advanced">
                    <Link to="/uitgebreid-zoeken/" title={advanced_search} className="nav-link">
                        {advanced_search}
                    </Link>
                </div>
            </div>
        </div>
    )
}

/**
 * Export the HeaderSearch variable
 */
export default HeaderSearch


/**
 * Calculate the percentages of results
 * @param results
 * @param amount_of_results
 * @returns {*[]}
 */
function calculateLinesPerPostType(results, amount_of_results) {

    // keep track of the total amount of results
    let total_results = 0;

    // loop through the results and determine the total results
    for (let i = 0; i < results.length; i++) {
        total_results += results[i].length;
    }

    // define the output
    let output_lines = [];

    // define the sum of percentages
    let sum = 0;

    // loop through the results
    // => mark we skip the last one from the array
    // => we process that one later
    for (let i = 0; i < results.length - 1; i++) {

        // calculate the percentage
        let percentage = (results[i].length / total_results);

        // calculate how many lines to add in search result for this post type
        let lines_for_this_post_type = Math.ceil(percentage * amount_of_results);

        // when adding this number would exceed the total amount, only add the remaining lines left
        if ((sum + lines_for_this_post_type) > amount_of_results) {
            lines_for_this_post_type = Math.floor(amount_of_results - sum);
        }

        // add them to the array
        output_lines[i] = lines_for_this_post_type;

        // calculate the sum
        sum += lines_for_this_post_type;
    }

    // calculate the remaining item
    output_lines[results.length - 1] = Math.floor(amount_of_results - sum);

    // return an array with percentages
    return output_lines;
}

/**
 * Loop through the array of results, and call displayResultsForPostType() for each of them
 * @param collection
 * @param query
 * @param lines_per_post_type
 * @returns {*}
 */
function processResults(collection, query, lines_per_post_type) {

    // return the results
    return (

        // for each collection (posts, recipes, hotspots) create results
        collection.map((results, index) => {
            return displayResultsForPostType(results, query, lines_per_post_type[index]);
        })
    )
}

/**
 * Display the results
 * @param results
 * @param query
 * @param lines_for_this_post_type
 * @returns {*|string}
 */
function displayResultsForPostType(results, query, lines_for_this_post_type) {

    // return results
    return (

        // check if all the data is filled in
        (typeof document != "undefined" && results.length > 0 && document.getElementById('s').value !== '' ?

                // for each item, create a search result item
                results.map((post, index) => {

                    // show max ?? items
                    if (index < lines_for_this_post_type) {
                        return (<SearchResult post={post} index={index} key={index} query={query}/>)
                    } else {
                        return ''
                    }
                })

                // otherwise
                :

                // return empty string
                ''
        )
    )
}