/**
 * NavPills navigation component used in the header
 */

// Import statements
import React from "react"
import {Link} from "gatsby"


// define the menu wrapper
// https://gregbastianelli.com/gatsby-wordpress-menu-api
const NavPills = ({menu, ul_class}) => (
    <ul className={ul_class}>
        {menu.map(menuItem => {
            const children = menuItem.childItems.nodes.length
                ? menuItem.childItems.nodes
                : null

            return children ? (
                <DropdownMenuItem parent={menuItem} children={children}/>
            ) : (
                <MenuItem menuItem={menuItem} key={menuItem.label}/>
            )
        })}
    </ul>
)

/**
 * Get an menu item
 * @param menuItem
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = ({menuItem}) => (
    <li className="nav-item">
        <a href={menuItem.path} className="nav-link">{menuItem.label}</a>
    </li>
)

/**
 * Parse the dropdown items
 * @param parent
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const DropdownMenuItem = ({parent, children}) => {
    //const [show, setShow] = useState(false)

    return (
        <li className={`dropdown menu-item`}>
            {parent.label}
            <div className="flex-square">
                {children.map(child => (
                    <Link to={child.path}>{child.label}</Link>
                ))}
            </div>
        </li>
    )
}

/**
 * Export the NavPills variable
 */
export default NavPills