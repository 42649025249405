/**
 * Seo component to provide the page with SEO data
 */

// Import statements
import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
//import { useStaticQuery, graphql, withPrefix } from "gatsby"
import {graphql, useStaticQuery} from "gatsby"

/**
 * Define the Seo constant
 * @param description
 * @param lang
 * @param meta
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const Seo = ({description, lang, meta, title}) => {
    const {wp, wpUser} = useStaticQuery(
        graphql`
      query {
        wp {
          generalSettings {
            title
            description
            url
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }
      }
    `
    )

    // get the meta description from the query.
    // as alternative use the generic one from the blog.
    const metaDescription = description || wp.generalSettings?.description

    // set the page title
    const defaultTitle = wp.generalSettings?.title
    //const defaultHostname = wp.generalSettings?.url

    // return the information
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: wpUser?.twitter || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
            /*script={[
              {
                type: `text/javascript`,
                src: withPrefix('scripts/main.js'),
              },
            ].concat()}*/
        />
    )
}

/**
 * Set default values
 * @type {{meta: *[], description: string, lang: string}}
 */
Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

/**
 * Define the datatypes for the used variables in the Seo constant
 * @type {{meta: Requireable<(object | undefined | null)[]>, description: Requireable<string>, lang: Requireable<string>, title: Validator<NonNullable<string>>}}
 */
Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

/**
 * Export the Seo variable
 */
export default Seo
