/**
 * Card component that is used on overview and single pages
 */

// Import statements
import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

/**
 * Define the Card constant
 * @param post
 * @param index
 * @param featured
 * @returns {JSX.Element}
 * @constructor
 */
const Card = ({post, index, featured}) => {

    // define variables
    const title = post.title
    const image = (post.featuredImage !== null) ? getImage(post.featuredImage.node.localFile) : ""

    // define the class
    let articleClass = "col-xs-12 col-sm-4 col-md-4 col-lg-3"
    if ([0, 1].indexOf(index) > -1 && featured === "true") {
        articleClass = "col-xs-12 col-sm-6 col-md-6 col-lg-6"
    }

    // build the card
    return (
        <article className={articleClass}>
            <div className="card card-overlay-bottom">
                <div className="image_wrapper">
                    <GatsbyImage image={image} alt={title} title={title}/>
                </div>
                <div className="card-img-overlay d-flex align-items-center p-4 p-sm-2 p-md-3">
                    <div className="w-100 mt-auto">
                        <b className="text-white">
                            <a href={post.uri} title={title} className="btn-link stretched-link text-reset">
                                {title}
                            </a>
                        </b>
                    </div>
                </div>
            </div>
        </article>
    )
}

/**
 * Export the Card variable
 */
export default Card
